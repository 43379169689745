<template>
  <div id="morenext">
    <top :name="text" />
    <!-- 商品进度 -->
    <div class="keys" style="margin-top: 0.2rem">
      <van-steps
        :active="active"
        v-if="active != 4"
        active-icon="success"
        active-color="#38f"
      >
        <van-step>待支付</van-step>
        <van-step>待发货</van-step>
        <van-step>待收货</van-step>
        <van-step>交易完成</van-step>
      </van-steps>
    </div>
    <div class="shopping">
      <div class="a">
        <img class="imghhh" :src="success_data.commodity_img" alt="" />
      </div>
      <div style="position: relative; flex: 1.6">
        <p
          class="bn"
          style="
            font-size: 0.3rem;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          "
        >
          {{ success_data.commodity_name }}
        </p>
        <p class="bn" style="font-size: 0.2rem; color: gray">
          {{ success_data.commodity_sku }}
        </p>
        <div class="zuoyou">
          <p :style="success_data.skunum == 1 ? 'color:red' : ''">
            ￥{{ success_data.item_pricing }} + 0积分
          </p>
          <p>×{{ success_data.skunum }}</p>
        </div>
        <p
          v-if="success_data.skunum != 1"
          style="color: red; font-size: 0.32rem"
        >
          ￥{{ success_data.total_price }} + 0积分
        </p>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="next">
      <van-cell-group>
        <van-cell
          title="订单信息"
          arrow-direction="down"
          is-link
          size="large"
        />
        <!-- 订单信息共有 -->
        <div>
          <van-cell title="订单编号" :value="success_data.order" />
          <van-cell title="创建时间" :value="success_data.establish_time" />
        </div>
        <!-- 订单信息共有 End-->
        <!-- 待支付 -- 订单信息 -->
        <!-- <div class=""></div> -->
        <!-- 待发货 -- 订单信息 -->
        <div class="" v-if="active != 0 && active != 4">
          <van-cell title="支付时间" :value="success_data.pay_time" />
          <van-cell title="支付单号" :value="success_data.wx_pay_order" />
        </div>
        <!-- 待收货 -- 订单信息 -->
        <div class="" v-if="active == 2 || active == 3">
          <van-cell title="物流单号" :value="success_data.Logistics_order" />
        </div>
        <!-- 订单关闭（已取消） -- 订单信息 -->
        <div class="" v-if="active == 4">
          <van-cell title="取消原因" :value="success_data.cosing_reason" />
          <van-cell title="取消时间" :value="success_data.cosing_time" />
        </div>
        <!-- 退款/售后 -- 订单信息 -->
        <div class="">
          <!-- <van-cell title="物流单号" value="SF151554159874" /> -->
        </div>
      </van-cell-group>
    </div>
    <!-- 收货人信息 共有-->
    <div class="next">
      <van-cell-group>
        <van-cell
          title="收货人信息"
          arrow-direction="down"
          is-link
          size="large"
        />
        <!-- 收货人信息共有 -->
        <div>
          <van-cell title="姓名" :value="success_data.receiving_name" />
          <van-cell title="手机号码" :value="success_data.receiving_phone" />
          <van-cell title="地址" :value="success_data.receiving_address" />
        </div>
      </van-cell-group>
    </div>
    <!-- 物流信息 等待后台回填南航物流单号 待收货状态 -->
    <div v-if="active == 2 || active == 3">
      <div class="block"></div>
      <div class="block_position" @click="logistics">
        <p style="letter-spacing: 0.04rem">查看物流状态</p>
      </div>
    </div>

    <!-- <div class="wuliu_div" v-if="active == 2 || active == 3">
      <van-steps direction="vertical" :active="0">
        <van-step>
          <h3>【城市】物流状态1</h3>
          <p>2016-07-12 12:40</p>
        </van-step>
        <van-step>
          <h3>【城市】物流状态2</h3>
          <p>2016-07-11 10:00</p>
        </van-step>
        <van-step>
          <h3>快件已发货</h3>
          <p>2016-07-10 09:30</p>
        </van-step>
      </van-steps>
    </div> -->
    <!-- 物流信息 End -->
    <!-- 待支付下 显示支付按钮 -->
    <div v-if="active == 0">
      <div class="block"></div>
      <div class="block_position" @click="paly">
        <p>支 付</p>
      </div>
    </div>

    <!-- 支付按钮 End -->
  </div>
</template>

<script>
import top from "../components/filltop.vue";
import wx from "weixin-jsapi";
import { Dialog } from "vant";
export default {
  data() {
    return {
      text: "订单详情",
      active: 1,
      success_data: {},
    };
  },
  components: {
    top,
  },
  methods: {
    status_function() {
      // 根据后端传输过来的状态码 改变成对应的状态码 active
      // 待支付
      "010" == this.success_data.status && (this.active = 0);
      // 待发货
      "011" == this.success_data.status && (this.active = 1);
      // 待收货
      "012" == this.success_data.status && (this.active = 2);
      // 已完成(交易完成)
      "013" == this.success_data.status && (this.active = 3);
      // 已取消
      "014" == this.success_data.status && (this.active = 4);
      // 如果订单状态是已取消?订单已关闭 ： 订单详情
      this.active == 4 ? (this.text = "订单已关闭") : "订单详情";
    },
    // 物流信息查询
    logistics(){
      this.$toast.fail("物流进度请自行查询，未开通")
    },
    // 支付
    paly() {
      var that = this;
      this.$https(
        "mall/payorder",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&wxid=" +
          sessionStorage.getItem("wx_openid") +
          "&source=" +
          "1" +
          "&url=" +
          encodeURIComponent(window.location.href.split("#")[0]) +
          "&orderid=" +
          this.success_data.order,
        "post"
      ).then((success_res) => {
        console.log("微信下单返回", success_res);
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wx0343cf05decc1643", // 必填，公众号的唯一标识
          timestamp: success_res.data.data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: success_res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: success_res.data.data.paySign, // 必填，签名
          jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
        }),
          wx.ready(() => {
            wx.chooseWXPay({
              timestamp: success_res.data.data.timeStamp,
              nonceStr: success_res.data.data.nonceStr,
              package: success_res.data.data.package,
              signType: "RSA",
              paySign: success_res.data.data.paySign,
              success: function () {
                // 用户支付成功
                // 订单详情
                that
                  .$https(
                    "mall/orderDetails",
                    "phone=" +
                      sessionStorage.getItem("user_phone") +
                      "&orderid=" +
                      that.success_data.order +
                      "&id=" +
                      sessionStorage.getItem("user_id"),
                    "post"
                  )
                  .then((res) => {
                    if (res.data.code == "000000") {
                      that.success_data = res.data.data;
                      that.status_function();
                    } else {
                      that.$toast.fail("加载失败，请重新进入");
                    }
                  });
              },
              fail: function () {
                that.onbayWXnavto(success_res);
                Dialog.alert({
                  title: "查询支付状态",
                }).then(() => {
                  that
                    .$https(
                      "mall/orderDetails",
                      "phone=" +
                        sessionStorage.getItem("user_phone") +
                        "&orderid=" +
                        that.success_data.order +
                        "&id=" +
                        sessionStorage.getItem("user_id"),
                      "post"
                    )
                    .then((res) => {
                      if (res.data.code == "000000") {
                        that.success_data = res.data.data;
                        that.status_function();
                      } else {
                        that.$toast.fail("加载失败，请重新进入");
                      }
                    });
                });
              },
              cancel: function (err) {
                // 用户取消支付 订单详情不做处理
              },
            });
          }),
          wx.error((res) => {
            alert("进行wx.error失败");
          });
      });
    },
    onbayWXnavto(succ) {
      // 用户支付失败 再跳转小程序进行支付 在小程序页面进行订单删除操作
      //
      // npm方式注入sdk失败  操作dom创建标签
      var wxjs = document.createElement("script");
      wxjs.type = "text/javascript";
      wxjs.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      document.body.appendChild(wxjs);
      // 异步调用sdk方法
      wxjs.onload = () => {
        // 伪定义 wx
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url:
            "/webH5/wxpay/wxpay?success_res=" +
            encodeURIComponent(JSON.stringify(succ)),
        });
      };
    },
  },
  mounted() {
    // 获取订单列表传输过来的详情信息
    this.success_data = JSON.parse(this.$route.params.success_data);
    this.status_function();
  },
  created() {},
};
</script>


<style scoped>
.block {
  width: 100%;
  height: 0.94rem;
}
.block_position {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 0.3rem;
  background: white;

  border-top: 1px solid #efefef;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
}
.block_position p {
  display: block;
  color: white;
  font-size: 0.36rem;
  background: #1652c7;
  padding: 0.1rem;
  width: 80%;
  border-radius: 0.2rem;
  text-align: center;
}
#morenext {
  background: #f8f8f8;
}
.wuliu_div {
  width: 96%;
  height: auto;
  background: white;
  margin: 0 auto;
  /* margin-top: 0.2rem; */
}
.next {
  width: 96%;
  height: auto;
  background: white;
  /* margin-top: 0.2rem; */
  margin: 0.2rem auto;
}
.shopping {
  width: 96%;
  background: white;
  padding: 0.1rem;
  display: flex;
  height: auto;
  margin: 0.3rem auto 0;
  border-radius: 0.2rem;
}
.a {
  flex-basis: 41%;
  height: 2rem;
  display: flex;
  align-items: center;
  border: 0.01rem solid rgb(204, 204, 204);
  border-radius: 0.14rem;
  flex: 1;
  justify-content: center;
  margin-top: 0.2rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.imghhh {
  width: 80%;
  height: auto;
}
.shopping:nth-child(2) {
  font-size: 0.3rem;
}
.zuoyou {
  display: flex;
  font-size: 0.28rem;
  align-content: center;
  justify-content: space-between;
}
.bn {
  margin: 0.2rem 0 0.2rem 0;
}
</style>
